<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-layout wrap px-5 pt-5>
      <v-flex xs12 sm8 md8 text-left>
        <span style="color: #000; font-family: poppinsbold; font-size: 25px"
          >User List</span
        >
      </v-flex>
      <v-flex xs12 sm2 md2>
        <v-select
          dense
          :items="items"
          v-model="userType"
          label="User Type"
          outlined
          item-text="name"
          item-value="value"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm2 md2 text-right pa-2>
        <v-btn
          tile
          class="px-5"
          small
          outlined
          :href="ipURL + '/admin/user/list/xlsx'"
          target="_blank"
          color="warning"
          style="text-transform: none"
        >
          <span
            style="font-size: 14px; font-family: poppinssemibold; color: #000"
          >
            Download Excel</span
          >
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout wrap pt-5 px-5>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="user"
          :items-per-page="limit"
          hide-default-footer
          class="elevation-1 .text--black"
          id="virtual-scroll-table"
        >
          <template v-slot:[`item.create_date`]="{ item }">
            <span v-if="item.create_date">{{
              item.create_date.slice(0, 10)
            }}</span>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            <span v-if="item.role == 'User'">Student</span>
            <span v-else>{{ item.role }}</span>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <span v-if="item.phone"
              >{{ item.countryCode }} {{ item.phone }}</span
            >
          </template>
          <template v-slot:[`item._id`]="{ item }">
            <v-icon small class="mr-2" @click="info(item)"> mdi-eye </v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-2>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 1">
          <v-pagination
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="green"
            circle
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
      timeout: 5000,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 20,
      user: [],
      items: [
        { name: "All", value: "" },
        { name: "Student", value: "Student" },
        { name: "Institution", value: "Institution" },
      ],
      userType: "",
      keyword: "",
      headers: [
        { text: "Name", value: "name", width: "140px" },
        { text: "Email", value: "email", width: "100px" },
        { text: "Phone", value: "phone", width: "80px" },
        { text: "Role", value: "role", width: "80px" },
        { text: "Date", value: "create_date", width: "80px" },
        { text: "Actions", value: "_id", width: "50px" },
      ],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
    userType() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/admin/user/list",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          limit: this.limit,
          page: this.currentPage,
          userType: this.userType,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    info(id) {
      this.$router.push("/userDetails?id=" + id._id);
    },
  },
};
</script>
<style>
.v-data-table-header {
  background-color: #cccac6;
  font-size: 20px;
  color: white;
}

.v-data-table .v-data-table-header tr th {
  font-size: 15px !important;
  color: black;
  font-family: poppinsmedium;
}

.v-data-table tr td {
  font-size: 13px !important;
  color: black;
  font-family: poppinsmedium;
}

#virtual-scroll-table {
  max-width: 1200px;
  overflow: auto;
}
</style>